import React from 'react';
 import { getColor } from '../../helpers'
 import { getTintedColor } from '../../helpers'




const FilterButtonBullet =(props)=>{
const label=props.label || props.text;
const searchterm=props.searchterm || props.text;



	let color =getColor(props.text);
	 var style = {
      background: 'white',
      fontSize: 200
    };


const handleMouseDown=()=>{
	// scroll back top
	window.scroll(0, 0);

	(props.active)?props.removeSearchterm(searchterm):props.addSearchterm(searchterm,props.fields,props.minYear,props.maxYear,props.type)
}


	return(
		<div>
			<button onMouseDown={ () => handleMouseDown() } className={`filterButton flex text-left fw focus:outline-none hover:text-gray-100  mt-1 ${props.active ? "text-gray-100 underline hover:text-gray-300 " : "text-gray-300 "}`}>{(props.bullet)?<div className="dot w-2 h-2 mt-2 mr-2 mb-005 rounded-full  bg-blue-500" style={{backgroundColor: color}}></div>:<div className=" w-2  h-2 mr-2 mb-0  " ></div>}<div className="block ">{label}</div></button>
		</div>
	)

}

FilterButtonBullet.defaultProps = { 
	fields:['subject','title',],
	bullet:false,
	type:'some type'
};


export default FilterButtonBullet;

