export const SubjectButtonsData = [
  {
    text: "Design Research, Project‑Grounded Research",
    bullet: true,
    fields: ["subject"],
    type: "subject",
  },
  {
    text: "Moholy-Nagy, Bauhaus, New Bauhaus, HfG Ulm",
    bullet: true,
    fields: ["subject"],
    type: "subject",
  },
  {
    text: "Design Ethics, Social Design",
    bullet: true,
    fields: ["subject"],
    type: "subject",
  },
  {
    text: "Design Theory, Epistemology, Methodology, Complexity",
    bullet: true,
    fields: ["subject"],
    type: "subject",
  },
  {
    text: "Design Education",
    bullet: true,
    fields: ["subject"],
    type: "subject",
  },
  {
    text: "Landscape Studies",
    bullet: true,
    fields: ["subject"],
    type: "subject",
  },
  {
    text: "Design History",
    bullet: true,
    fields: ["subject"],
    type: "subject",
  },
];

export const LanguageButtonsData = [
  {
    text: "English",
    searchterm: "en",
    fields: ["language"],
    bullet: false,
    type: "language",
  },
  {
    text: "French",
    searchterm: "fr",
    fields: ["language"],
    bullet: false,
    type: "language",
  },
  {
    text: "German",
    searchterm: "dt",
    fields: ["language"],
    bullet: false,
    type: "language",
  },
];

export const YearButtonsData = [
  {
    text: "1986–1990",
    minYear: "1986",
    maxYear: "1990",
    fields: ["year_of_publication"],
    bullet: false,
    type: "year",
  },
  {
    text: "1991–1995",
    minYear: "1991",
    maxYear: "1995",
    fields: ["year_of_publication"],
    bullet: false,
    type: "year",
  },
  {
    text: "1996–2000",
    minYear: "1996",
    maxYear: "2000",
    fields: ["year_of_publication"],
    bullet: false,
    type: "year",
  },
  {
    text: "2001–2005",
    minYear: "2001",
    maxYear: "2005",
    fields: ["year_of_publication"],
    bullet: false,
    type: "year",
  },
  {
    text: "2006–2010",
    minYear: "2006",
    maxYear: "2010",
    fields: ["year_of_publication"],
    bullet: false,
    type: "year",
  },
  {
    text: "2011–2015",
    minYear: "2011",
    maxYear: "2015",
    fields: ["year_of_publication"],
    bullet: false,
    type: "year",
  },
  {
    text: "2016–2020",
    minYear: "2016",
    maxYear: "2020",
    fields: ["year_of_publication"],
    bullet: false,
    type: "year",
  },
  {
    text: "2021–2024",
    minYear: "2021",
    maxYear: "2024",
    fields: ["year_of_publication"],
    bullet: false,
    type: "year",
  },
];
